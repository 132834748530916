<script setup lang="ts">
/**
 * @author Beka Chkhaidze
 */
import { onBeforeMount, computed, ref, watch } from "vue";
import { useMainStore } from "@/store/main";
import type { ImgDevices } from "@/store/main/types/component/UI";

const props = withDefaults(
  defineProps<{
    devices: ImgDevices;
    alt: string;
    imgClass?: string;
    isLazy?: boolean;
    parallax?: boolean;
  }>(),
  {
    imgClass: "img-absolute",
    isLazy: true,
    parallax: false,
  }
);

const store = useMainStore();

const windowWidth = computed(() => store.windowWidth);

const noImg = ref(false);

watch(
  () => props.devices,
  (ns) => {
    if (windowWidth.value <= store.breakpoints.mobile && !ns?.mobile) {
      noImg.value = true;
    }

    if (
      windowWidth.value > store.breakpoints.mobile &&
      windowWidth.value <= store.breakpoints.tablet &&
      !ns?.tablet
    ) {
      noImg.value = true;
    }

    if (windowWidth.value > store.breakpoints.tablet && !ns?.desktop) {
      noImg.value = true;
    }
  },
  {
    immediate: true,
  },
);

onBeforeMount(() => {
  if (!props.devices) {
    console.warn("[Picture]: You forgot to pass ':devices' props");
  }

  if (!props.alt) {
    console.warn(
      `\n\n[Picture]: You forgot to pass ':alt' props\nPlease check README.md\n\n`
    );
  }
});
</script>

<template>
  <img
    v-if="noImg"
    :class="imgClass"
    :alt="alt || 'image'"
    src="@/assets/images/NoImg.svg"
    decoding="async"
    loading="lazy"
  />

  <picture v-else data-picture-component>
    <source
      :media="`(max-width:${store.breakpoints.mobile}px)`"
      :srcset="devices?.mobile"
    />
    <source
      :media="`(max-width:${store.breakpoints.tablet}px)`"
      :srcset="devices?.tablet"
    />
    <NuxtImg
      :data-scroll="parallax"
      :data-scroll-speed="parallax ? -2 : ''"
      :class="imgClass"
      :src="devices?.desktop"
      :alt="alt || 'image'"
      :data-original="devices?.desktop"
      :loading="isLazy ? 'lazy' : 'eager'"
      decoding="async"
    />
  </picture>
</template>

<style lang="scss">
.img-absolute {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  // backface-visibility: hidden;

  &--contain {
    object-fit: contain;
  }
}
</style>
